import React, { useState } from "react";
import {
  Toolbar,
  Typography,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { makeStyles, AppBar, Button, IconButton } from "@material-ui/core";
import logoImg from "../assets/staticPages/logo.png";
const useStyles = makeStyles((theme) => ({
  appBar: {
    height: "134px",
    padding: "30px",
    paddingLeft: "5px",
    paddingRight: "5px",
    backgroundColor: "white",
    color: "black",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  menuButton: {
    display: "block",
    color: "#1E0E62",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  logoMobile: {
    flexGrow: 1,
    display: "block",
    color: "black",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  logoBox: {
  "@media (max-width: 1100px)": {
      flexGrow: 1,
      justifyContent: "center",
      display: "flex",
    },
  },
  logo: {
    height: "90px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      height: "60px",
    },
  },
  navLinks: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexGrow: 1,
      justifyContent: "center",
      alignItems: "center",
    },
  },
  navButton: {
    paddingLeft: 10,
    paddingRight: 10,
    marginRight: "40px",
    fontSize: "22px",
    fontWeight: 600,
    textTransform: "none",
    textAlign: "center",
    display: "flex",
    color: "#1E0E62",
    [theme.breakpoints.down("md")]: {
      paddingRight: "16px",
      fontSize: "16px",
    },
  },
  contactButton: {
    width: "146px",
    height: "54px",
    fontSize: "18px",
    textTransform: "none",
    backgroundColor: "#021CA5",
    color: "white",
    borderRadius: "100px",
    "&:hover": {
      backgroundColor: "#F29F05",
    },
    [theme.breakpoints.down("md")]: {
      width: "110px",
      height: "40px",
      fontSize: "16px",
    },
  },
  drawerContainer: {
    width: 250,
  },
  ListItemText:{
    textDecoration: "none", color: "#1E0E62"
  }
}));

const Header = () => {
  const classes = useStyles();
  const staticPage = localStorage.getItem("staticPage");
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar>
        <IconButton
          edge="start"
          aria-label="menu"
          className={classes.menuButton}
          onClick={toggleDrawer(true)}
        >
          <MenuIcon />
        </IconButton>
        <Box className={classes.logoBox}>
        <a href="/fWH5Z38g">
          <img
            className={classes.logo}
            src={logoImg}
            alt="logo"
            style={{ paddingTop: 10 }}
          />
          </a>
        </Box>
        <Box className={classes.navLinks}>
          <Button className={classes.navButton} href="/003MVy9Q">
            <span
              style={{
                borderBottom: staticPage === "about" && "3px solid #021CA5",
              }}
            >
              About
            </span>
          </Button>
          <Button className={classes.navButton} href="/004MVy9Q">
            <span
              style={{
                borderBottom:
                  staticPage === "differentiation" && "3px solid #021CA5",
              }}
            >
              Differentiation
            </span>
          </Button>
          <Button className={classes.navButton} href="/005MVy9Q">
            <span
              style={{
                borderBottom: staticPage === "services" && "3px solid #021CA5",
              }}
            >
              Services
            </span>
          </Button>
          <Button className={classes.navButton} href="/006MVy9Q">
            <span
              style={{
                borderBottom:
                  staticPage === "productAgnostic" && "3px solid #021CA5",
              }}
            >
              Product Agnostic
            </span>
          </Button>
          <Button className={classes.navButton} href="/007MVy9Q">
            <span
              style={{
                borderBottom:
                  staticPage === "DAMClasses" && "3px solid #021CA5",
              }}
            >
              DAM Classes
            </span>
          </Button>
        </Box>
        <Button variant="contained" className={classes.contactButton}>
          Contact
        </Button>
        <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
          <Box
            className={classes.drawerContainer}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <List>
              {[
                "About",
                "Differentiation",
                "Services",
                "Product Agnostic",
                "DAM Classes",
              ].map((text) => (
                <ListItem button key={text}>
                  {text === "About" && (
                    <ListItemText
                      primary=<a
                        href="/003MVy9Q"
                        className={classes.ListItemText}
                      >
                        {text}
                      </a>
                    />
                  )}
                  {text === "Differentiation" && (
                    <ListItemText
                      primary=<a
                        href="/004MVy9Q"
                        className={classes.ListItemText}
                      >
                        {text}
                      </a>
                    />
                  )}
                  {text === "Services" && (
                    <ListItemText
                      primary=<a
                        href="/005MVy9Q"
                        className={classes.ListItemText}
                      >
                        {text}
                      </a>
                    />
                  )}
                  {text === "Product Agnostic" && (
                    <ListItemText
                      primary=<a
                        href="/006MVy9Q"
                        className={classes.ListItemText}
                      >
                        {text}
                      </a>
                    />
                  )}
                  {text === "DAM Classes" && (
                    <ListItemText
                      primary=<a
                        href="/007MVy9Q"
                        className={classes.ListItemText}
                      >
                        {text}
                      </a>
                    />
                  )}
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
